/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { UserPlus as UserPlusIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import Proptypes from 'prop-types';

import theme from '@/theme';
import { currencyFormatter } from '@/utils/functions';
import { MMS } from '@/utils/Proptypes';

import 'dayjs/locale/th';

import NameBox from '../../Box/Common/NameBox';

dayjs.locale('th');
dayjs.extend(buddhistEra);

/**
 * @function StepTable
 * @description ตารางรายการงาน (Works Table) หรือ Steps ใช้ในหน้าของรายการงาน (Works)
 * @memberof MMS
 * @returns {React.Component} React Component
 */
export default function StepTable({
  step,
  page,
  size,
  total,
  handleChangeRowsPerPage,
  handleAcceptWork,
  handleToInfoPage,
  handleChangePage,
  handleAuditStep = () => {},
}) {
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const [selectStepId, setSelectStepId] = useState(null);
  const [count, setCount] = useState(0);
  const showWorkResponsible = (row) => {
    if (row?.responsible?.employee && !_.isEmpty(row?.responsible?.employee)) {
      return <NameBox user={row?.responsible?.employee} />;
    }
    return <div> ยังไม่มีผู้รับผิดชอบในงานนี้</div>;
  };

  const handleSpecialFunctionForAudit = (id) => {
    if (id === selectStepId) {
      setCount(count + 1);
    } else {
      setSelectStepId(id);
      setCount(1);
    }
  };

  useEffect(() => {
    if (count === 5) {
      handleAuditStep(selectStepId);
    }

    return () => {};
  }, [count]);

  return (
    <div className="w-full my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="10%" />
              <col width="15%" />
              <col width="35%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่มอบหมาย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่องาน</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">แผนก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ผู้รับผิดชอบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(step?.rows) ? (
                step?.rows?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <p>{dayjs(row?.createdAt).format('D MMM BBBB ')}</p>
                      <p>{dayjs(row?.createdAt).format('เวลา HH:mm น.')}</p>
                      {row?.log && (
                        <Chip label="ลบ หรือ สำเร็จแล้ว" size="small" />
                      )}
                    </TableCell>
                    <TableCell>
                      <Link
                        className="text-theme-500 text-lg font-bold font-display cursor-pointer hover:text-indigo-900"
                        to={`/mms/works/view/${row?._id}`}
                        style={{
                          color: theme?.palette?.primary?.dark,
                        }}
                      >
                        {row?.name}
                      </Link>
                      <Link
                        className="text-gray-500 cursor-pointer hover:text-gray-900"
                        to={
                          information?.setting?.mms?.dashboard?.showDashboardC
                            ? `/mms/manufacturing-orders-c/view/${row?.manufacturing_order}`
                            : `/mms/manufacturing-orders/view/${row?.manufacturing_order}`
                        }
                      >
                        <div className="text-base font-semibold hover:text-indigo-900 ">
                          MO
                          {row?.mo_number}{' '}
                          {information?.setting?.mms?.showBatchNumber && (
                            <Chip
                              size="small"
                              label={`Batch No.${row?.batch_no || ''}`}
                            ></Chip>
                          )}
                        </div>
                      </Link>{' '}
                      <div className="flex">
                        {row?.current && (
                          <div className="p-1 px-2 my-2 text-green-600 bg-green-100 rounded-md ">
                            อยู่ในขั้นตอนของคุณ
                          </div>
                        )}{' '}
                      </div>
                      <div className="">
                        <span className="font-bold mr-2">
                          {row?.product?.type_code}
                        </span>{' '}
                        <span>{row?.product?.name}</span>{' '}
                      </div>
                      <div>
                        {(row?.deleted || row?.process?.deleted) && (
                          <div className="text-red-500 font-semibold">
                            ยกเลิกแล้ว
                          </div>
                        )}
                      </div>
                      {typeof row?.process !== 'string' ? (
                        <div>
                          {row?.process?.quantity >= 1 && (
                            <div>
                              จำนวน{' '}
                              {currencyFormatter.format(row?.process?.quantity)}{' '}
                              {row?.process?.additional_unit &&
                              row?.process?.additional_unit !== ''
                                ? row?.process?.additional_unit
                                : row?.process?.produce_material_instead
                                ? row?.process?.product_as_material?.unit
                                : row?.process?.product?.unit}
                              {(!row?.process?.additional_unit &&
                                row?.process?.produce_base_project_instead &&
                                row?.process?.base_project?.unit) ||
                                ''}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div
                        className="mt-2"
                        onClick={() => {
                          handleSpecialFunctionForAudit(row?._id);
                        }}
                      >
                        ลูกค้า {row?.customer?.type_code || ''}{' '}
                        {row?.customer?.name || '-'}
                      </div>
                      <div className="mt-2">
                        ต้องการสินค้า{' '}
                        {row?.expected_date
                          ? dayjs(row?.expected_date).format('D MMM BBBB')
                          : '-'}
                      </div>
                    </TableCell>

                    <TableCell>{row?.responsible?.department?.name}</TableCell>

                    <TableCell>
                      <div className="w-48">{showWorkResponsible(row)}</div>
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-col flex-wrap  gap-1 ">
                        {!row?.completed && !row?.deleted && (
                          <div>
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              disabled={
                                !(
                                  (!row?.responsible?.employee ||
                                    _.isEmpty(row?.responsible?.employee)) &&
                                  (me?.userData?.role?.level >= 1 ||
                                    row?.responsible?.department?._id ===
                                      me?.userData?.department?._id)
                                )
                              }
                              startIcon={<UserPlusIcon size={16} />}
                              onClick={() => {
                                handleAcceptWork(row);
                              }}
                            >
                              รับงาน
                            </Button>{' '}
                          </div>
                        )}
                        <Link to={`/mms/works/view/${row?._id}`}>
                          <Button
                            variant="contained"
                            color={'info'}
                            size={'small'}
                          >
                            รายละเอียด
                          </Button>
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Paper>
    </div>
  );
}

StepTable.propTypes = {
  step: MMS.StepStage,
  page: Proptypes.number,
  size: Proptypes.number,
  total: Proptypes.number,
  handleChangeRowsPerPage: Proptypes.func.isRequired,
  handleChangePage: Proptypes.func.isRequired,
  handleToInfoPage: Proptypes.func.isRequired,
  handleStartStep: Proptypes.func.isRequired,
  handleAcceptWork: Proptypes.func.isRequired,
  handleAuditStep: Proptypes.func,
};
