/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import Proptypes from 'prop-types';

import { MMS } from '@/utils/Proptypes';

import CardStep from './CardStep';

dayjs.locale('th');
dayjs.extend(buddhistEra);

/**
 * @function CarsStepList
 * @description รายการ (Works Card) หรือ Steps ใช้ในหน้าของรายการงาน (Works)
 * @memberof MMS
 * @returns {React.Component} React Component
 */
export default function CardStepList({
  step,
  page,
  size,
  total,
  handleChangeRowsPerPage,
  handleAcceptWork,
  handleAuditStep = () => {},
}) {
  const [selectStepId, setSelectStepId] = useState(null);
  const [count, setCount] = useState(0);

  const handleSpecialFunctionForAudit = (id) => {
    if (id === selectStepId) {
      setCount(count + 1);
    } else {
      setSelectStepId(id);
      setCount(1);
    }
  };

  useEffect(() => {
    if (count === 5) {
      handleAuditStep(selectStepId);
    }

    return () => {};
  }, [count]);

  return (
    <div className="w-full my-2">
      <div>
        {!_.isEmpty(step?.rows) ? (
          step?.rows?.map((row, index) => (
            <CardStep
              key={index}
              row={row}
              handleAcceptWork={handleAcceptWork}
              handleSpecialFunctionForAudit={handleSpecialFunctionForAudit}
            />
          ))
        ) : (
          <div className="my-2 p-4 text-center">ไม่พบรายการงาน</div>
        )}
      </div>
      <div className="flex justify-center my-2">
        <div>
          <div className="flex justify-center">
            <Button
              variant="outlined"
              disabled={page * size >= total}
              onClick={() => {
                handleChangeRowsPerPage({ target: { value: size + 5 } });
              }}
            >
              + เพิ่ม
            </Button>
          </div>
          <div className="text-sm">
            {page * size >= total ? total : page * size} จากทั้งหมด {total || 1}{' '}
            รายการ{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

CardStepList.propTypes = {
  step: MMS.StepStage,
  page: Proptypes.number,
  size: Proptypes.number,
  total: Proptypes.number,
  handleChangeRowsPerPage: Proptypes.func.isRequired,
  handleStartStep: Proptypes.func.isRequired,
  handleAcceptWork: Proptypes.func.isRequired,
  handleAuditStep: Proptypes.func,
};
