import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { MANUFACTURING_TRANSACTION_TYPE } from '@/utils/constants';

import { CancleProcessModal } from '../../Modals';
import { ProcessReport } from '../../Report';
import { BrachingStepTimeline, TimelineStepper } from '../../Timeline';
import AllMaterialUsageCard from '../Material/AllMaterialUsageCard';
import BoxPreferMaterial from '../Material/BoxPreferMaterial';
import BoxRemainingMaterial from '../Material/BoxRemainingMaterial';
import MaterialSourceBox from '../Material/MaterialSourceBox';
import BoxResultingFunction from '../MMS/BoxResultingFunction';
import CommentBox from '../MMS/CommentBox';
import ManufacTransactionBox from '../MMS/ManufacTransactionBox';
import MeasurementVariableListBox from '../MMS/MeasurementVariableListBox';
import ProductAsMaterialResultBox from '../Product/ProductAsMaterialResultBox';
import ProductResultBox from '../Product/ProductResultBox';
import PluginDisplayRenderingComponent from '../Rendering/PluginDisplayRenderingComponent';

import ProcessControlBox from './ProcessControlBox';
import ProcessDetailBox from './ProcessDetailBox';
import ProcessNoInfoBox from './ProcessNoInfoBox';
import ProcessStartTimeBox from './ProcessStartTimeBox';
import ProcessStatusBox from './ProcessStatusBox';

const ProcessActionContainerBox = ({
  selectedProcess,
  manufacturingOrder,
  getManufacturingOrder = () => {},
  referenceNumber,
  setDialogLoadingOpen,
}) => {
  const [modalCancleProcessOpen, setModalCancleProcessOpen] = useState(false);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);

  const dispatch = useDispatch();

  const handleCancleProcess = () => {
    setModalCancleProcessOpen(true);
  };

  const handlePrint = () => {
    try {
      ProcessReport(selectedProcess, manufacturingOrder, information);
    } catch (error) {
      window.alert(`Error on Print ${error?.message}`);
      ProcessReport(selectedProcess, manufacturingOrder, information, true);
    }
  };
  const handleDeleteProcess = async (data) => {
    const confirm = window.confirm('ยืนยันการยกเลิกไลน์การผลิต');
    const { delete_note = '' } = data;
    try {
      if (confirm) {
        setModalCancleProcessOpen(false);
        await dispatch(
          actions.processPut(selectedProcess?._id, {
            deleted: true,
            employeeId: me?.userData?._id,
            delete_note,
            referenceNumber,
            manufacturing_transaction_type:
              MANUFACTURING_TRANSACTION_TYPE.PROCESS_CANCLE.status_code,
          }),
        );
        setDialogLoadingOpen(true);
        getManufacturingOrder();
      }
    } catch (error) {
      console.error('Error on Cancle Process', error);
      window.alert(`ยกเลิกไลน์การผลิตไม่สำเร็จ ${error?.message}`);
    }
  };

  return (
    <div className="my-2">
      <CancleProcessModal
        isOpen={modalCancleProcessOpen}
        handleClose={() => setModalCancleProcessOpen(false)}
        selectedProcess={selectedProcess}
        manufacturingOrder={manufacturingOrder}
        successHandle={(data) => {
          handleDeleteProcess(selectedProcess, data);
        }}
      />
      <div className="my-2">
        <ProcessNoInfoBox
          me={me}
          selectedProcess={selectedProcess}
          information={information}
          handleCancle={handleCancleProcess}
          handlePrint={handlePrint}
        />
      </div>
      <Card className="my-2">
        <CardContent>
          <div className="w-full">
            {_.includes(
              _.map(selectedProcess?.steps, (each) => each?.splited_step),
              true,
            ) ? (
              <BrachingStepTimeline
                processSteps={selectedProcess?.steps}
                allowDrag={false}
                currentProcess={selectedProcess}
              />
            ) : (
              <TimelineStepper
                information={information}
                selectedProcess={selectedProcess}
              />
            )}
          </div>
          <div className="my-2 pt-4">
            <ProcessControlBox
              currentStep={selectedProcess?.current?.step}
              process={selectedProcess}
              justify="center"
              container={false}
            />
          </div>
        </CardContent>
      </Card>
      <PluginDisplayRenderingComponent
        currentProcess={selectedProcess}
        currentStep={selectedProcess?.current?.step}
        manufacturingOrder={manufacturingOrder}
      />
      <div className="flex flex-wrap mt-4 my-2">
        <div className="w-full lg:w-1/2 xl:w-1/3 px-1">
          <Card>
            <CardContent>
              <h4 className="font-bold font-display">วัตถุดิบ</h4>
              {selectedProcess?.isRequestMaterial ? (
                <BoxRemainingMaterial
                  manufacturingMaterials={
                    selectedProcess.manufacturing_materials
                  }
                  selectedProcess={selectedProcess}
                />
              ) : (
                <BoxPreferMaterial
                  processMaterialList={selectedProcess?.materials}
                />
              )}
            </CardContent>
          </Card>
        </div>
        <div className="w-full  lg:w-1/2 xl:w-1/3 mt-2 lg:mt-0 px-1">
          <Card>
            <CardContent>
              <ProcessStatusBox selectedProcess={selectedProcess} />
            </CardContent>
          </Card>{' '}
          <Card className="my-2">
            <CardContent>
              <ProcessDetailBox selectedProcess={selectedProcess} />
            </CardContent>
          </Card>
          <Card className="my-2">
            <CardContent>
              <ProcessStartTimeBox
                selectedProcess={selectedProcess}
                manufacturingOrder={manufacturingOrder}
              />
            </CardContent>
          </Card>
        </div>
        <div className="  w-full lg:w-1/2 xl:w-1/3 mt-2 lg:mt-2 xl:mt-0 px-1">
          <Card>
            <CardContent>
              <h4 className="font-bold font-display">Timeline การผลิต</h4>
              <ManufacTransactionBox
                manufacturingTransaction={{
                  rows: selectedProcess?.manufacturing_transactions,
                }}
                showTitle={false}
                container={false}
              />
            </CardContent>
          </Card>
        </div>
      </div>
      {!_.isEmpty(selectedProcess?.measurement_type_data) && (
        <Card className="my-2">
          <CardContent>
            <h4 className="font-bold font-display">ค่าที่วัดผล</h4>
            <div className="w-full mt-2">
              <MeasurementVariableListBox selectedProcess={selectedProcess} />
            </div>
          </CardContent>
        </Card>
      )}
      {!_.isEmpty(selectedProcess?.resulting_function_types) && (
        <Card className="my-2">
          <CardContent>
            <h4 className="font-semibold font-display">ผลจากการวัด</h4>
            <BoxResultingFunction
              currentProcess={selectedProcess}
              currentStepIndex={selectedProcess?.current?.index}
            />
          </CardContent>
        </Card>
      )}

      {/* <Card>
          <CardContent>
            <h4 className="font-semibold font-display"> กระบวนการทำงาน</h4>
            <div className="flex flex-wrap justify-center ">
              {!_.isEmpty(selectedProcess?.steps) && (
                <div className="w-full lg:w-1/3">
                  <StepEmployee steps={selectedProcess?.steps} />
                </div>
              )}
            </div>
          </CardContent>
        </Card> */}
      <div className="flex flex-wrap my-2">
        <div className="w-full lg:w-1/2 xl:w-1/3 px-1">
          <Card className="my-2">
            <CardContent>
              <div className="flex justify-between items-center">
                <div className="font-semibold font-display">ผลผลิต</div>
                <div>
                  {information?.external_software?.ewarehouse?.enable && (
                    <Link
                      to={`/mms/process/product-barcode/${selectedProcess?._id}`}
                    >
                      <Button size="small">พิมพ์บาร์โค้ด</Button>
                    </Link>
                  )}
                </div>
              </div>
              <ProductResultBox
                information={information}
                selectedProcess={selectedProcess}
              />
              <ProductAsMaterialResultBox
                information={information}
                selectedProcess={selectedProcess}
              />
            </CardContent>
          </Card>
        </div>
        <div className="w-full lg:w-1/2 xl:w-1/3 px-1">
          <Card className="my-2">
            <CardContent>
              <div className="font-semibold font-display">การใช้วัตถุดิบ</div>
              <AllMaterialUsageCard selectedProcess={selectedProcess} />
            </CardContent>
          </Card>
        </div>{' '}
        <div className="w-full lg:w-1/2 xl:w-1/3 px-1">
          <Card className="my-2">
            <CardContent>
              <div className="font-semibold font-display">ที่มาวัตถุดิบ</div>
              <MaterialSourceBox
                selectedProcess={selectedProcess}
                information={information}
              />
            </CardContent>
          </Card>
        </div>
        <div className="w-full">
          <Card>
            <CardContent>
              <h4 className="font-semibold font-display"> ความคิดเห็น</h4>
              <CommentBox
                comment={{ rows: selectedProcess?.comments }}
                processId={selectedProcess?._id}
                showTitle={false}
                container={false}
                handleSuccess={getManufacturingOrder}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProcessActionContainerBox;

ProcessActionContainerBox.propTypes = {
  selectedProcess: PropTypes.object,
  manufacturingOrder: PropTypes.object,
  getManufacturingOrder: PropTypes.func,
  referenceNumber: PropTypes.string,
  setDialogLoadingOpen: PropTypes.func,
};
