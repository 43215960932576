import React from 'react';
import { UserPlus as UserPlusIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Chip } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import theme from '@/theme';
import { currencyFormatter } from '@/utils/functions';

import NameBox from '../Box/Common/NameBox';

function CardStep({ row, handleAcceptWork, handleSpecialFunctionForAudit }) {
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);

  const showWorkResponsible = (each) => {
    if (
      each?.responsible?.employee &&
      !_.isEmpty(each?.responsible?.employee)
    ) {
      return (
        <div className="text-sm">
          <NameBox user={each?.responsible?.employee} />
        </div>
      );
    }
    return <div> ยังไม่มีผู้รับผิดชอบในงานนี้</div>;
  };

  return (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="text-sm flex gap-2">
            <p>{dayjs(row?.createdAt).format('D MMM BBBB ')}</p>
            <p>{dayjs(row?.createdAt).format('เวลา HH:mm น.')}</p>
            {row?.log && <Chip label="ลบ หรือ สำเร็จแล้ว" size="small" />}
          </div>

          <div>
            <Link
              className="text-theme-500 text-lg font-bold font-display cursor-pointer hover:text-indigo-900"
              to={`/mms/works/view/${row?._id}`}
              style={{
                color: theme?.palette?.primary?.dark,
              }}
            >
              {row?.name}
            </Link>
            <Link
              className="text-gray-500 cursor-pointer hover:text-gray-900"
              to={
                information?.setting?.mms?.dashboard?.showDashboardC
                  ? `/mms/manufacturing-orders-c/view/${row?.manufacturing_order}`
                  : `/mms/manufacturing-orders/view/${row?.manufacturing_order}`
              }
            >
              <div className=" hover:text-indigo-900 text-sm">
                <span className="font-semibold">
                  MO
                  {row?.mo_number}{' '}
                </span>
                {information?.setting?.mms?.showBatchNumber && (
                  <Chip
                    size="small"
                    label={`Batch No.${row?.batch_no || ''}`}
                  ></Chip>
                )}
                {row?.current && (
                  <span className="p-1 px-2 m-1 text-green-600 text-sm bg-green-100 rounded-md ">
                    อยู่ในขั้นตอนของคุณ
                  </span>
                )}
              </div>
            </Link>{' '}
            <hr className="my-2" />
            <div className="flex justify-center text-sm my-2">
              <div>{showWorkResponsible(row)}</div>
            </div>
            <hr className="my-2" />
            <div className="">
              <span className="font-bold mr-2">{row?.product?.type_code}</span>{' '}
              <span>{row?.product?.name}</span>{' '}
            </div>
            <div>
              {(row?.deleted || row?.process?.deleted) && (
                <div className="text-red-500 font-semibold">ยกเลิกแล้ว</div>
              )}
            </div>
            {typeof row?.process !== 'string' ? (
              <div>
                {row?.process?.quantity >= 1 && (
                  <div>
                    จำนวน {currencyFormatter.format(row?.process?.quantity)}{' '}
                    {row?.process?.additional_unit &&
                    row?.process?.additional_unit !== ''
                      ? row?.process?.additional_unit
                      : row?.process?.produce_material_instead
                      ? row?.process?.product_as_material?.unit
                      : row?.process?.product?.unit}
                    {(!row?.process?.additional_unit &&
                      row?.process?.produce_base_project_instead &&
                      row?.process?.base_project?.unit) ||
                      ''}
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {row?.customer?.name && (
              <div
                className="mt-2"
                onClick={() => {
                  handleSpecialFunctionForAudit(row?._id);
                }}
              >
                ลูกค้า {row?.customer?.type_code || ''}{' '}
                {row?.customer?.name || '-'}
              </div>
            )}
          </div>
          <div className="w-full flex">
            <div className="mt-1 w-1/2">
              <div className="text-sm">ต้องการสินค้า </div>
              {row?.expected_date
                ? dayjs(row?.expected_date).format('D MMM BBBB')
                : '-'}
            </div>
            <div className="mt-1 w-1/2">
              <div className="text-sm">แผนก </div>
              {row?.responsible?.department?.name}
            </div>
          </div>

          <hr className="my-2" />
          <div>
            <div className="flex gap-1 justify-center">
              {!row?.completed && !row?.deleted && (
                <div>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={
                      !(
                        (!row?.responsible?.employee ||
                          _.isEmpty(row?.responsible?.employee)) &&
                        (me?.userData?.role?.level >= 1 ||
                          row?.responsible?.department?._id ===
                            me?.userData?.department?._id)
                      )
                    }
                    startIcon={<UserPlusIcon size={16} />}
                    onClick={() => {
                      handleAcceptWork(row);
                    }}
                  >
                    รับงาน
                  </Button>{' '}
                </div>
              )}
              <Link to={`/mms/works/view/${row?._id}`}>
                <Button variant="contained" color={'info'} size={'small'}>
                  รายละเอียด
                </Button>
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default CardStep;

CardStep.propTypes = {
  row: PropTypes.object,
  handleAcceptWork: PropTypes.func,
  handleSpecialFunctionForAudit: PropTypes.func,
};
