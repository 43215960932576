import Dashboard from './Dashboard/Dashboard';
import DetailMeasuredData from './MeasurementVariable/DetailMeasuredData';
import MeasuredData from './MeasurementVariable/MeasuredData';
import DetailMaterialOnProcess from './Process/DetailMaterialOnProcess';
import EditMaterialUsageTransaction from './Process/EditMaterialUsageTransaction';
import DetailProductProcess from './Setting/SettingDetailProductProcess';
import EditProductProcess from './Setting/SettingEditProductProcess';
import DetailWork from './Work/DetailWork';
import EditStepMeasurement from './Work/EditStepMeasurement';
import HistoryWork from './Work/HistoryWork';
import MyWorks from './Work/MyWorks';
import PluginEditData from './Work/PluginEditData';
import Works from './Work/Works';
import DashboardView from './Dashboard';
import ManufacturingOrderViews from './ManufacturingOrder';
import ProcessView from './Process';
import ReportView from './Report';
import SettingView from './Setting';

/**
 * @namespace MMS
 * @description Manufacturing Management System - การผลิต การเบิกวัตถุดิบ สินค้า การจัดการ BOM
 */

export {
  Dashboard,
  DashboardView,
  DetailMaterialOnProcess,
  DetailMeasuredData,
  DetailProductProcess,
  DetailWork,
  EditMaterialUsageTransaction,
  EditProductProcess,
  EditStepMeasurement,
  HistoryWork,
  ManufacturingOrderViews,
  MeasuredData,
  MyWorks,
  PluginEditData,
  ProcessView,
  ReportView,
  SettingView,
  Works,
};

export default {
  Dashboard,
  DetailMaterialOnProcess,
  DetailMeasuredData,
  DetailProductProcess,
  DetailWork,
  PluginEditData,
  EditMaterialUsageTransaction,
  EditProductProcess,
  HistoryWork,
  MeasuredData,
  MyWorks,
  Works,
  EditStepMeasurement,
  ...ManufacturingOrderViews,
  ...ReportView,
  ...ProcessView,
  ...SettingView,
  ...DashboardView,
};
