import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
} from '@mui/material';
import PropTypes from 'prop-types';

const MeasurementDataTable = ({
  measurement,
  page,
  size,
  total,
  handleChangePage,
  handleChangeRowPerPage,
}) => (
  <div>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <div className="font-bold">ลำดับที่</div>
          </TableCell>
          <TableCell>
            <div className="font-bold"> หัวข้อที่ 1</div>
          </TableCell>
          <TableCell>
            <div className="font-bold">หัวข้อที่ 2</div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!_.isEmpty(measurement?.rows) ? (
          _.map(measurement?.rows, (row, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <div className="text-xs">{index + 1}</div>
              </TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <div className="text-center">ไม่มีข้อมูล</div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>

    <TablePagination
      page={page - 1}
      count={total || 1}
      rowsPerPage={size}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowPerPage}
    />
  </div>
);

MeasurementDataTable.propTypes = {
  measurement: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowPerPage: PropTypes.func,
};

export default MeasurementDataTable;
