import {
  MEASUREMENT_ALL,
  MEASUREMENT_GET,
  MEASUREMENT_DELETE,
  MEASUREMENT_PUT,
  MEASUREMENT_POST,
  MEASUREMENT_LOADING,
  MEASUREMENT_ERROR,
} from '../types';

import api from '../../../configs/api';

export const measurementCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: MEASUREMENT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/measurement`,
      {
        ...payload,
      },
    );
    dispatch({ type: MEASUREMENT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MEASUREMENT_ERROR });
    throw new Error(error);
  }
};

export const measurementAll = ({
  currentProcess = '',
  step = '',
  size = 10,
  page = 1,
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/measurement?process=${currentProcess}&step=${step}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: MEASUREMENT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MEASUREMENT_ERROR });
    throw new Error(error);
  }
};

export const measurementGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/measurement/${id}`,
    );
    if (status === 200) {
      dispatch({ type: MEASUREMENT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: MEASUREMENT_ERROR });
    throw new Error(error);
  }
};

export const measurementStateSet = (data) => async (dispatch) => {
  dispatch({ type: MEASUREMENT_ALL, payload: data });
};

export const measurementPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: MEASUREMENT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/measurement/${id}`,
      payload,
    );
    dispatch({ type: MEASUREMENT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MEASUREMENT_ERROR });
    throw new Error(error);
  }
};
export const measurementDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: MEASUREMENT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/measurement/${id}`,
    );
    dispatch({ type: MEASUREMENT_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: MEASUREMENT_ERROR });
    throw new Error(error);
  }
};
