/* eslint-disable no-confusing-arrow */
import dayjs from 'dayjs';

import { converseMaterialOrProductUnit } from '../functions/converseMaterialOrProductUnit';
import { MATERIAL_REQUEST_STATUS } from '../constants';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'วันที่',
    value: (row) => `${dayjs(row?.createdAt)?.format('D/MM/BBBB')}`,
  },
  {
    label: 'เวลา',
    value: (row) => `${dayjs(row?.createdAt)?.format('HH:mm')}`,
  },
  {
    label: 'รหัสวัตถุดิบ',
    value: (row) => row?.material?.type_code,
  },
  {
    label: 'วัตถุดิบ',
    value: (row) => row?.material?.name,
  },
  {
    label: 'จำนวน',
    value: (row) => row?.quantity,
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.material?.use_unit_conversion
        ? col?.material?.unit_input?.short_sign
        : col?.material?.unit,
  },
  {
    label: 'คงเหลือ (หน่วยที่สอง)',
    value: (col) =>
      col?.material?.use_unit_conversion
        ? converseMaterialOrProductUnit(col?.material, col?.quantity)
        : '-',
  },
  {
    label: 'หน่วย',
    value: (col) =>
      col?.material?.use_unit_conversion
        ? col?.material?.unit_output?.short_sign
        : '-',
  },
  {
    label: 'ผู้สั่งซื้อ',
    value: 'request_name',
  },
  {
    label: 'แผนกผู้สั่งซื้อ',
    value: (row) => row?.request_department?.name,
  },
  {
    label: 'สถานะ',
    value: (row) => MATERIAL_REQUEST_STATUS[row?.status]?.description,
  },
];

export default { columns };
